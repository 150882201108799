
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import { FormInstance } from 'element-plus';
import { store } from "@/store";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Validations from '@/core/services/etc/Validations';
import { etc, rule, notification, informationModal } from '@/store/stateless';
import { corporateTop } from '@/core/utils'
import router from '@/router/clean';
import Customers from '@/core/services/Callable/Customers';
import Etcs from '@/core/services/Callable/Etcs';

export default defineComponent({
    setup() {
        const corporateForm = ref<FormInstance>()

        const isSubmitted = ref(false)
        const top = ref()
        const last = ref()
        const empRef = ref()
        const loading = ref(false)
        const health = computed(() => {
            return store.state.customer.health;
        })

        const categories = computed(() => {
            return store.state.etc.employeeCategories
        })

        const rules = reactive({
                name: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
                email: [{ validator: Validations.email, trigger: ['blur', 'change'] }],
                phone_number: [{ validator: Validations.phone_number, trigger: ['blur', 'change'] }],
                dob: [{ validator: Validations.dob, trigger: ['blur'] }],
                gender: [{ validator: Validations.gender, trigger: ['blur'] }],
                is_salary: [{ validator: Validations.is_salary, trigger: ['blur', 'change'] }],
                is_self: [{ validator: Validations.is_self, trigger: ['blur', 'change'] }],
                age_group: [{ validator: Validations.age_group, trigger: ['blur', 'change'] }],
                insured_city: [{ validator: Validations.insured_city, trigger: ['blur', 'change'] }],
                // insurance_type: [{ validator: Validations.insurance_type, trigger: ['blur'] }],
                company_name: [{ validator: Validations.company_name, trigger: ['blur', 'change'] }],
                expected_insurance_start_date: [{ validator: Validations.expected_insurance_start_date, trigger: ['blur', 'change'] }],
                nationality_id: [{ validator: Validations.nationality, trigger: ['blur', 'change'] }],
                // agent: [{ validator: Validations.agent, trigger: ['blur', 'change'] }]
            })

        onMounted(async () => {
            setCurrentPageBreadcrumbs("Edit Group Details", [{ label: "Leads & Tasks", value: "CustomerLists" }]);
            // health.value.insured_type_id = 4;
            // health.value.insured_type_id_prev = 4;
            // health.value.insurance_type = 2;
            Customers.getLeadsByLeadId({
                id: parseInt(router.currentRoute.value.params.lead_id.toString())
            })
            // console.log(health.value.categories);
            
            Etcs.getEmployeeCategories();
            await Etcs.getInsurancePolicies({isDropdown: true, isGroup:true });
            if(health.value.categories.length == 0)
                await addNewCategory();
        })

        const addNewCategory = () => {
            if(!corporateForm.value) return

            const name = health.value.categories.map((x, i) => {
                return [`categories.${i}.name`, `categories.${i}.visa_emirates`, `categories.${i}.insurance_plan_id`]
            }).flat()

            if(health.value?.categories.length > 0)  
                corporateForm.value.validateField(name)
                .then((valid) => {
                    if(valid) {
                        health.value?.categories?.push({
                            id: null,
                            name: null,
                            insurance_provider_id: null,
                            insurance_providers: store.state.etc.providers,
                            insurance_plan_id: null,
                            insurance_policies: [],
                            group_count: 1,
                            members_above_60: 0,
                            visa_emirates: null,
                            
                            visa_emirates_text: null,
                            category_name: null,
                            insurance_plan: null,
                            amount:0
                        })
                    }
                })

            if(health.value.categories.length == 0) {
                health.value?.categories?.push({
                    id: null,
                    name: null,
                    insurance_provider_id: null,
                    insurance_providers: store.state.etc.providers,
                    insurance_plan_id: null,
                    insurance_policies: [],
                    group_count: 1,
                    members_above_60: 0,
                    visa_emirates: null,
                    
                    visa_emirates_text: null,
                    category_name: null,
                    insurance_plan: null,
                    amount:0
                })
            }  
        }

        const submitHealth = async (formEl: FormInstance | undefined) => {
            isSubmitted.value = true
            console.log(health.value);
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                if (valid) {
                    if(checkDuplicateCategory()) {
                        loading.value = true
                        const result = await Customers.updateLead(health.value);
                        if (result.status == 200) {
                            notification.success(
                                'Customer',
                                'Customer has been updated successfully'
                            );
                            // reset();
                            // formEl.resetFields();
                            goLists(result.data.data.customer.id);
                        } else {
                            const { email_taken, phone_taken } = result.response.data.data
                            Validations.email_taken = email_taken
                            Validations.phone_taken = phone_taken
                            formEl.validate(async (valid) => {
                            })
                        }
                    } else {
                            // open the popup
                            loading.value = false
                            informationModal.value.open = true
                            informationModal.value.title = 'Duplicate Group Details'
                            informationModal.value.message = 'Category and Visa Issued Emirate is duplicated in the group details section. Kindly update the group details information to proceed.'
                        }
                } else {
                    rule.customerDetails =  Object.keys(fields)[0]
                    if(corporateTop.includes(rule.customerDetails)) top.value.focus(); 
                    else empRef.value.focus()

                }

                loading.value = false
                isSubmitted.value = false

            })
        }

        const goLists = (customer_id: number) => {
            router.replace({ name: 'CustomerDetails', params: {customer_id} })
        }

        const checkDuplicateCategory = () => {
            const lookup = health.value.categories
            let isValid = true
            const oldValue = <any>[]

            for(const i of lookup) {
                if(oldValue.length > 0) {
                    let checker = oldValue.filter(x => x.name == i.name && x.visa_emirates == i.visa_emirates)
                    if(checker.length > 0) isValid = false
                }

                oldValue.push(i)
            }

            return isValid
        }

        return {
            checkDuplicateCategory,
            top,
            empRef,
            health,
            rules,
            corporateForm,
            addNewCategory,
            submitHealth,
            goLists,
            categories,
            loading
        }

    },
})
